.c-community-hastags-page__container {
  max-width: var(--container-width-sm);
  margin: 0 auto;
  padding-bottom: 2rem;
  width: 90%;
}

.c-community-hastags-page__container .c-list-card {
  color: var(--g-color-black);
  padding: var(--g-spacing-large) var(--g-spacing-2x-large) var(--g-spacing-large) var(--g-spacing-x-large);
}

.c-community-hastags-page__container .c-list-card__display-name {
  padding: 0;
}
