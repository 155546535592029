.c-notifications-menu {
  margin-left: 0.5rem;
}

.c-notifications-menu__message {
  font-size: var(--g-font-size-x-small);
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.c-notifications-menu ul {
  margin: 0;
}

.c-notifications-menu li {
  font-size: var(--g-font-size-x-small);
  margin: 0;
}

.c-notifications-menu li img {
  margin-right: 0.35rem;
  max-width: 0.85rem;
}

.c-notifications-menu .c-notifications-menu__button {
  align-items: center;
  color: Var(--g-color-black);
  cursor: pointer;
  display: inline-flex;
  font-size: var(--g-font-size-x-small);
  padding: var(--g-spacing-x-small) var(--g-spacing-small);
  width: 100%;
}

.c-notifications-menu .c-notifications-menu__button:hover {
  background-color: #f1f4f5;
}

.c-notifications-menu li:last-of-type .c-notifications-menu__button {
  background-color: #f1f4f5;
  justify-content: center;
}

.c-notifications-menu .c-dropdown-menu__dropdown {
  width: 15rem;
}

.c-notifications-menu__status {
  background-color: var(--color-primary);
  border-radius: 50%;
  height: var(--g-spacing-x-small);
  margin-right: var(--g-spacing-x-small);
  min-width: var(--g-spacing-x-small);
  width: var(--g-spacing-x-small);
}

.c-notifications-menu__count {
  align-items: center;
  background-color: #d85e2e;
  border-radius: 50%;
  color: var(--g-color-white);
  display: flex;
  height: 1rem;
  font-size: 0.65rem;
  font-weight: 600;
  justify-content: center;
  margin-bottom: 0;
  margin-left: auto;
  min-width: 1rem;
  width: 1rem;
}
