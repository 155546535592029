.c-range-slider__thumb:active > .c-range-slider__value {
  opacity: 1;
}

.c-range-slider__thumb > .c-range-slider__value {
  opacity: 0;
  transition: 0.3s;

}
.c-range-slider__thumb {
  background-color: #fff !important;
  border-radius: 50%;
  box-shadow: rgb(9 30 66 / 31%) 0 0 4px, rgb(9 30 66 / 25%) 0 20px 32px -8px;
  cursor: pointer;
  height: 2rem !important;
  outline: none;
  position: absolute;
  top: 0;
  width: 2rem !important;
  z-index: 2;
}

.c-range-slider__track {
  background-color: var(--color-primary) !important;
  border-radius: 50px;
  height: 1rem !important;
  position: relative;
  /* margin-bottom: var(--spacing-12); */
  margin-top: var(--spacing-12);
  width: 100%;
}

.c-range-slider__value {
  box-shadow: rgb(9 30 66 / 31%) 0 0 4px, rgb(9 30 66 / 25%) 0 20px 32px -8px;
  background-color: var(--white);
  border: 2px solid var(--color-primary);
  border-radius: 50%;
  color: var(--color-primary);
  height: var(--spacing-6);
  width: var(--spacing-6);
  align-items: flex-end;
  display: flex;
  justify-content: center;
  position: absolute;
  top: -5px;
  transform: translate(-50%, -100%);
  left: 50%;
  font-weight: 600;
  font-size: 0.85rem;
}

.c-range-slider__index {
  margin-top: 2rem;
}

.c-question-snippet .c-dompurify-sanitized-html-content {
  align-items: center;
  display: flex;
}

.c-range-slider__mark::before {
  content: '';
  background-color: #c7ccd8;
  display: block;
  height: 1rem;
  position: absolute;
  right: 0;
  top: 100%;
  transform: translateY(50%);
  visibility: visible;
  width: 1px;
}

.c-range-slider__mark {
  display: none;
  position: relative;
  visibility: hidden;
}

.c-range-slider__mark:first-of-type,
.c-range-slider__mark:last-of-type {
  color: var(--blue-800);
  font-family: var(--font-header);
  font-weight: 800;
  margin-top: var(--spacing-10);
  visibility: visible;
}

.c-range-slider__mark:first-of-type::before,
.c-range-slider__mark:last-of-type::before {
  display: none;
}

@media (max-width: 46em) {
  .c-range-slider__label {
    transform: translate(-50%, 208%) rotate(-90deg) !important;
    transform-origin: center;
  }

  #ek-section-12347,
  #ek-section-12185,
  #ek-section-12186 {
    padding-bottom: 10rem;
  }
}
