.c-spinner__icon {
  animation: rotate 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff #fff #fff transparent;
  border-radius: 50%;
  border-style: solid;
  /* border-color: #2b3332 #2b3332 #2b3332 transparent; */
  height: var(--g-spacing-large);
  width: var(--g-spacing-large);
  border-width: var(--g-spacing-3x-small);
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
