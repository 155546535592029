.c-post {
  background-color: var(--g-color-white);
  border: 1px solid var(--color-athens);
  border-radius: 10px;
  margin-bottom: var(--g-spacing);
}

.c-post:only-child {
  margin-bottom: 0;
}

.c-post--highlighted {
  border: 2px solid var(--color-primary);
  box-shadow: 0px 2px 3px #262d5d40;
}

.c-post-_pin {
  align-items: center;
  background-color: var(--color-primary);
  border-radius: 50%;
  color: var(--g-color-white);
  display: flex;
  justify-content: center;
  height: 2rem;
  width: 2rem;
}

.c-post-user {
  border-bottom: 1px solid var(--color-athens);
  display: flex;
  padding: var(--g-spacing) var(--g-spacing-large);
}

.c-post-user__avatar {
  margin-right: var(--g-spacing-x-small);
  position: relative;
}

.c-post-user__details {
  display: flex;
  flex-direction: column;
}

.c-post-user__avatar .c-avatar,
.c-post-user__details .c-post-user__date {
  margin-bottom: 0;
}

.c-post-user__date {
  color: #848484;
  font-size: var(--g-font-size-x-small);
}

.c-post-user__display-name {
  color: var(--g-color-black);
  font-size: var(--g-font-size);
  font-weight: 600;
  line-height: 1;
  margin-bottom: 0;
}

.c-post-user__settings {
  display: flex;
  margin-left: auto;
}

.c-post-user__settings span {
  margin-bottom: 0;
}

.c-post-user__settings .c-icon {
  height: var(--g-spacing);
  width: var(--g-spacing);
}

.c-post-user__settings .c-dropdown-toggle {
  align-items: center;
  border: 2px solid var(--color-whisper);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: var(--g-spacing-x-large);
  justify-content: center;
  padding: 0;
  width: var(--g-spacing-x-large);
}

.c-post-message {
  padding: var(--g-spacing) var(--g-spacing-large);
  white-space: pre-wrap;
}

.c-post-message--highlight,
.c-post-message--highlight a {
  color: #487570;
  font-weight: 400;
}

.c-post-details {
  border-bottom: 1px solid var(--color-athens);
  border-top: 1px solid var(--color-athens);
  display: flex;
}

.c-post-details__item {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: var(--g-spacing-small) 0;
  width: 33.333%;
}

.c-post-details__item:not(:last-of-type) {
  border-right: 1px solid var(--color-athens);
}

.c-post-footer {
  padding: var(--g-spacing-x-small) var(--g-spacing-large);
}

.c-post-footer__comment-button {
  color: var(--color-primary);
  cursor: pointer;
  font-size: var(--g-font-size-large);
  font-weight: bold;
  margin-left: auto;
}

.c-post-user__avatar--skeletion {
  border-radius: 50%;
  height: var(--g-spacing-2x-large);
  width: var(--g-spacing-2x-large);
}

.c-post-user__display-name--skeleton {
  height: var(--g-spacing-small);
  width: var(--g-spacing-5x-large);
}

.c-post-user__date--skeleton {
  height: var(--g-spacing-x-small);
  width: var(--g-spacing-5x-large);
}

.c-post-message--skeleton {
  border-radius: 40px;
  height: var(--g-spacing-small);
}

.c-post-message span:last-of-type .c-post-message--skeleton {
  width: 30%;
}

.c-post-reply {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  margin: var(--g-spacing) 0;
  position: relative;
  width: 100%;
}

.c-feed-page__container .c-post-reply .c-button {
  position: absolute;
  bottom: 1.5rem;
  right: 1rem;
}

.c-feed-page__container .c-post-reply .c-emoji-selector {
  bottom: 1.5rem;
  right: 5.5rem;
}

.c-post-reply .c-textarea__container {
  width: 100%;
}

.c-post-reply textarea {
  min-height: 6rem;
}

.c-post__pin {
  width: var(--g-spacing);
}

.c-post-options ul,
.c-post-options li {
  margin: 0;
}

.c-post-options li button {
  cursor: pointer;
  font-size: var(--g-font-size-small);
  padding: var(--g-spacing-2x-small) var(--g-spacing-small);
  text-align: left;
  width: 100%;
}

.c-post-options li button:hover {
  background-color: #f2f5f6;
}

.c-post-relates {
  align-items: center;
  cursor: pointer;
  display: flex;
}

.c-post-relates span {
  color: #487570;
  font-size: var(--g-font-size-large);
  margin: 0;
  font-weight: 500;
  line-height: 1.5;
}

.c-post-relates__icon {
  align-items: center;
  display: flex;
  margin-right: var(--g-spacing);
  position: relative;
}

.c-post-relates__icon svg {
  position: relative;
}

.c-post-relates__icon::before {
  background-color: #2251ac;
  border-radius: 50%;
  content: '';
  height: 18px;
  left: 6px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  z-index: 0;
}

.c-post-image__preview {
  height: 5rem;
  overflow: hidden;
  padding-top: 0;
  position: relative;
}

.c-post-image__preview:before {
  box-shadow: inset 1px -33px 28px -3px rgba(255,255,255,1);
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
}

.c-post-image img {
  border-radius: 25px;
  display: block;
  margin: 0 auto var(--g-spacing) auto;
  max-height: 26rem;
  padding: var(--g-spacing);
}

.c-post-document {
  align-items: center;
  background-color: var(--grey-50);
  border: 1px solid var(--color-athens);
  border-radius: 50px;
  color: inherit;
  display: inline-flex;
  margin: var(--g-spacing);
  padding: var(--g-spacing-3x-small) var(--g-spacing);
}

.c-post-document svg {
  height: var(--g-spacing);
  margin-right: var(--g-spacing-2x-small);
  width: var(--g-spacing);
}

.c-post-video {
  margin: var(--g-spacing);
}

.c-post-video .c-video-player__video {
  max-width: 100%;
}

.c-post .c-post-feeling {
  padding: var(--g-spacing) var(--g-spacing) 0 var(--g-spacing);
}

.c-post__mention {
  background: #f1f4f5;
  border: 1px solid #bed1d8;
  border-radius: 40px;
  color: #000;
  font-size: var(--g-font-size-small);
  padding: 3px 6px;
}

.c-post__community {
  align-items: flex-end;
  display: inline-flex;
  margin-left: var(--g-spacing-x-small);
}

.c-post__community * {
  color: var(--g-color-black);
  font-size: 1.25rem;
  margin-bottom: 0;
}
