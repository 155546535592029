.c-get-help-button {
  position: fixed;
  right: 0;
  transform: rotate(270deg) translateY(100%);
  top: 70vh;
  z-index: 9;
}

@media(min-width: 64em) {
  .c-get-help-button {
    top: 64vh;
  }
}