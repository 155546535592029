.c-search-input,
.c-search-input__input {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.c-search-input.c-search-input--sm input {
  padding: var(--g-spacing-x-small) var(--g-spacing-small);
}

.c-search-input__input,
.c-input__container {
  flex: 1;
}

.c-search-input__input .c-input-field {
  margin-bottom: 0;
  width: 100%;
}

.c-search-input {
  flex: 1;
}

.c-search-input__icon {
  height: var(--g-spacing);
  left: var(--g-spacing-small);
  position: absolute;
  width: var(--g-spacing);
}

.c-search-input input {
  min-width: 17rem;
  padding: var(--g-spacing-small);
  padding-left: var(--g-spacing-large);
}

.c-search-input input:hover,
.c-search-input input:focus {
  border-color: var(--color-athens) !important;
}

.c-search-input__button {
  border: none;
  border-radius: 0 10px 10px 0 !important;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  padding: var(--g-spacing-x-small) var(--g-spacing);
}

.c-search-input__button--close {
  background-color: #2251ac !important;
  border: none !important;
  box-shadow: none !important;
  border-radius: 50% !important;
  align-items: center;
  display: flex;
  justify-content: center;
  height: var(--g-spacing-large);
  right: var(--g-spacing);
  width: var(--g-spacing-large);
  padding: 0;
}

.c-search-input__button .c-button__icon {
  color: var(--g-color-white);
  height: var(--g-spacing-large);
  width: var(--g-spacing-large);
}

.c-search-input__button--close .c-button__icon {
  height: var(--g-spacing);
  width: var(--g-spacing);
}

.c-search-input__clear {
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: var(--g-spacing);
  position: absolute;
  right: var(--g-spacing-small);
  width: var(--g-spacing);
}

.c-search-input__clear svg {
  height: 100%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.c-search-input .c-input__icon--before {
  color: #cbccce;
}
