.c-user-avatar {
  display: inline-flex;
  position: relative;
}

.c-user-avatar__icon {
  align-items: center;
  background-color: var(--color-accent);
  border-radius: 50%;
  color: var(--g-color-black);
  display: flex;
  height: 1rem;
  justify-content: center;
  padding: 2px;
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-40%, -15%);
  width: 1rem;
}