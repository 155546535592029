.c-checkin .c-tabset__nav {
  background-color: #fff;
  padding: 1rem 1rem 0 1rem;
  border-radius: 10px;
}

.c-checkin .c-tab {
  margin-bottom: 0;
}

.c-checkin__chart {
  background-color: #fff;
  border-radius: 10px;
}

.category-container {
  display: flex;
  margin: 0 0 0 1rem;
}

.category-container > li {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.checkin-timeline-select > div > label {
  display: none;
}

.category {
  cursor: pointer;
  align-items: center;
  border: 2px solid var(--color-primary);
  color: var(--color-primary);
  border-width: 3px;
  border-radius: 10px;
  display: inline-flex;
  justify-content: center;
  padding: 0.5rem 1rem;
  font-weight: 800;
  margin: 0 0.75rem 1rem 0;
  font-size: 0.875rem;
}

.category.active {
  background-color: var(--color-primary);
  color: var(--g-color-white);
}

.c-underline-tab__link--selected {
  border-color: var(--color-primary);
  color: var(--color-primary);
}

.c-underline-tab__link:hover,
.c-underline-tab__link:focus {
  color: var(--color-primary);
}
