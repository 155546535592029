.c-community-page__container {
  max-width: var(--container-width-sm);
  margin: 0 auto;
  padding-bottom: 2rem;
  width: 90%;
}

.c-community-page__container .c-search-input {
  margin-bottom: var(--g-spacing-large);
}

.c-community-page__moderator-toggle .c-label {
  display: flex;
}

.c-community-page__moderator-toggle .c-toggle-switch {
  margin-left: 0.5rem;
}

.c-community-page__moderator-toggle .h-spacing-small {
  margin: 0;
}

.c-community-page__container .c-information-banner img {
  max-width: 2rem;
}

.c-community-page__container .c-information-banner p {
  margin-bottom: 0;
}