.c-user-menu {
  color: var(--g-color-black);
  position: relative;
  margin-left: 1rem;
}

.c-user-menu__dropdown {
  background-color: var(--g-color-white);
  border-radius: 5px;
  color: var(--g-color-black);
  margin: 0;
  position: absolute;
  right: 0;
  transform: translateY(10px);
  top: 100%;
  width: 12rem;
  z-index: 999;
}

.c-user-menu__dropdown::before {
  background-color: var(--g-color-white);
  content: '';
  height: var(--g-spacing-x-small);
  position: absolute;
  width: var(--g-spacing-x-small);
  transform: translate(-50%, -50%) rotate(45deg);
  right: 5%;
  z-index: -1;
}

.c-user-menu .c-dropdown-toggle {
  padding: 0;
}

.c-user-menu__item {
  background-color: var(--g-color-white);
  overflow: hidden;
  margin: 0;
}

.c-user-menu__item:first-of-type {
  border-radius: 5px 5px 0 0;
}

.c-user-menu__item:last-of-type {
  border-radius: 0 0 5px 5px;
}

.c-user-menu__item:not(:last-of-type) {
  border-bottom: 1px solid #f2f5f6;
}

.c-user-menu__link {
  color: var(--g-color-black);
  cursor: pointer;
  display: flex;
  font-size: var(--g-font-size-small);
  padding: var(--g-spacing-x-small) var(--g-spacing);
  width: 100%;
}

.c-user-menu__link:hover {
  background-color: #f2f5f6;
}

.c-user-menu__logout {
  cursor: pointer;
  justify-content: center;
  width: 100%;
}

.c-user-menu .c-avatar {
  cursor: pointer;
  font-size: 1.25rem;
  margin-bottom: 0;
}
