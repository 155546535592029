.ek-section {
  background-color: var(--white);
  border: 1px solid var(--color-athens);
  border-radius: 15px;
  margin-bottom: 1rem;
  padding: 1.5rem;
  opacity: 0;
  animation: fadeUp 800ms cubic-bezier(0.23, 1, 0.32, 1) forwards;
}

/* .ek-section:hover {
  box-shadow: 0 3px 15px rgba(0, 164, 184, 0.1);
  transition: 200ms ease-in-out;
} */

.ek-section:hover .ek-section {
  box-shadow: none;
}

.ek-section .ek-section {
  background-color: transparent;
  border: none;
}
