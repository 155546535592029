.c-feedback-button {
  position: fixed;
  right: 0;
  transform: rotate(270deg) translateY(72px);
  top: 40vh;
  z-index: 9;
}

@media(min-width: 64em) {
  .c-feedback-button {
    transform: rotate(270deg) translateY(80px);

  }
}

@media(max-height: 650px) {
  .c-feedback-button {
    top: 28vh;
  }
}