.c-notifications-page__title {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--g-spacing-large);
}

.c-notifications-page__title h1 {
  font-family: var(--font-body);
  font-size: var(--g-font-size-2x-large);
}

.c-notifications-page .c-search-input {
  margin-bottom: var(--g-spacing-2x-large);
}

.c-notifications-page__toggle .c-label {
  align-items: center;
  display: flex;
}

.c-notifications-page__toggle .c-toggle-switch {
  margin-left: var(--g-spacing-small);
}

/* .c-notifications-page__toggle .c-toggle-switch__slider::before,
.c-notifications-page__toggle .c-toggle-switch__slider::after {
  top: 50%;
} */

.c-notifications-page h2 {
  font-size: var(--g-font-size-x-large);
  margin-bottom: var(--g-spacing);
}

.c-notifications-page h3 {
  font-size: var(--g-font-size-large);
  font-family: var(--font-body);
  margin-bottom: var(--g-spacing-x-small);
}

.c-notifications-page h4 {
  color: #707070;
  font-size: var(--g-font-size-x-small);
  font-family: var(--font-body);
  font-weight: 400;
  margin-bottom: var(--g-spacing);
}

.c-notifications-page__section {
  margin-bottom: var(--g-spacing-2x-large);
}
