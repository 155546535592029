.c-notifications {
  align-items: center;
  background-color: var(--color-whisper);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-left: auto;
  position: relative;
  height: var(--g-spacing-4x-large);
  width: var(--g-spacing-4x-large) !important;
}

.c-notifications__icon {
  color: var(--color-primary);
  width: var(--g-spacing-large);
}

.c-notifications__count {
  align-items: center;
  background-color: #d85e2e;
  border-radius: 50%;
  color: var(--g-color-white);
  display: flex;
  height: 1.2rem;
  font-size: 0.65rem;
  font-weight: 600;
  justify-content: center;
  position: absolute;
  right: 0;
  transform: translate(20%, 0%);
  top: 0;
  width: 1.2rem;
}
