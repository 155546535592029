/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

/* prettier-ignore */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  border: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  font-family: var(--font-body);
}

/* HTML5 display-role reset for older browsers */

/* prettier-ignore */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  min-height: 100vh;
}

ol,
ul {
  list-style: none;
  margin-left: var(--spacing-8);
}

blockquote,
q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: '';
  content: none;
}

abbr.is-required {
  /* color: var(--red-700);
  margin-left: var(--spacing-1);
  text-decoration: none; */
  display: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
  ****************
  * END CSS RESET
  ****************
**/

/* :root {
  font-size: var(--fs-base);
} */

html {
  scroll-behavior: smooth;
  -webkit-text-size-adjust: 100%;
}

html,
body {
  background-color: var(--grey-100);
  color: var(--black);
  font-family: var(--font-body);
  font-size: 14px;
  overflow-x: hidden;
}

@media print {
  html,
  body {
    background-color: #fff !important;
  }
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
}

input:not([type="range"]):not(.DateInput_input),
textarea,
.c-textarea,
.c-input,
.c-input-field__input,
.ant-picker,
select,
.c-select-list__input,
.c-password input {
  background-color: var(--white);
  border: 2px solid var(--color-athens);
  border-radius: 10px;
  font-family: var(--font-body);
  font-size: 1rem;
  outline: none;
  padding: 0.6rem 0.85rem;
  transition: 250ms ease-in-out;
  width: 100%;
}

.c-select-list {
  margin-bottom: 0;
}

.c-select-list__input {
  padding-right: 2rem;
}

.c-password__button {
  top: 3rem;
}

input {
  width: 100%;
}

textarea {
  min-height: 6rem;
}

input:not([type="range"]):not(.DateInput_input):hover,
textarea:hover,
.c-input:hover,
.c-textarea:hover {
  border: 2px solid !important;
  border-color: var(--color-primary) !important;
}

input:not([type="range"]):not(.DateInput_input):focus,
textarea:focus,
.c-input:focus,
.c-textarea:focus {
  border-color: var(--color-primary);
}

input:not([type="range"]):not(.DateInput_input):read-only,
input:disabled,
textarea:disabled,
.c-input:disabled {
  background-color: var(--grey-50);
  border-color: var(--color-athens);
}

input:not([type="range"]):not(.DateInput_input):read-only:hover {
  border-color: var(--color-athens) !important;
}

input:not([type="range"]):not(.DateInput_input):disabled,
textarea:disabled,
.c-input:disabled {
  cursor: not-allowed;
}

select:focus,
.c-select-list__input:focus {
  border: 2px solid var(--color-primary) !important;
  box-shadow: none !important;
}

button {
  background-color: transparent;
  border: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

a,
button {
  outline: none;
}

strong {
  font-weight: 600;
}

* {
  box-sizing: border-box;
}

p,
span,
li {
  font-family: var(--font-body);
  font-size: 1rem;
  line-height: 1.6;
  letter-spacing: var(--ls-normal);
  margin-bottom: var(--spacing-4);
}

small {
  font-size: 0.85rem;
  line-height: 1.4;
}

sup {
  font-size: 0.75rem;
  vertical-align: super;
}

i {
  font-style: italic;
}

h1,
.h1 {
  font-family: var(--font-header);
  font-size: 2.5rem;
  font-weight: 800;
  line-height: 1.1;
}

h2,
.h2 {
  font-family: var(--font-header);
  font-size: 2.125rem;
  font-weight: 800;
  line-height: 1.1;
}

h3,
.h3 {
  font-family: var(--font-header);
  font-size: 1.85rem;
  font-weight: 800;
  line-height: 1.1;
}

h4,
.h4 {
  font-family: var(--font-header);
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.82rem;
}

h5,
.h5 {
  font-family: var(--font-header);
  font-size: 1.23rem;
  line-height: 1.2;
}

h6,
.h6 {
  font-family: var(--font-header);
  font-size: 1rem;
  line-height: 1.4;
}

@media (min-width: 64em) {
  h1,
  .h1 {
    font-size: 3rem;
  }
}

@media (min-width: 64em) {
  html,
  body {
    font-size: 16px;
  }
}

.c-contact-form__heading,
.c-forgot-password-form__heading,
.c-login-form__heading,
.c-registration-form__heading,
.c-reset-password-form__heading {
  display: none;
}

.c-contact-form__email-container,
.c-contact-form__message-container,
.c-contact-form__phone-number-container,
.c-contact-form__first-name-container,
.c-contact-form__last-name-container,
.c-directory-form__contact-email-container,
.c-directory-form__contact-number-container,
.c-directory-form__postcode-container,
.c-directory-form__service-description-container,
.c-directory-form__site-organisation-name-container,
.c-directory-form__state-container,
.c-directory-form__street-container,
.c-directory-form__suburb-container,
.c-directory-form__website-url-container,
.c-forgot-password-form__email-container,
.c-login-form__email-container,
.c-login-form__password-container,
.c-registration-form__confirm-password-container,
.c-registration-form__email-container,
.c-registration-form__first-name-container,
.c-registration-form__last-name-container,
.c-registration-form__password-container,
.c-reset-password-form__confirm-password-container,
.c-reset-password-form__password-container,
.c-contact-number-form__phone-container,
.c-registration-form__displayName-container {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--spacing-4);
}

.c-directory-form__service-description-container,
.c-forgot-password-form__email-container,
.c-login-form__password-container,
.c-registration-form__confirm-password-container,
.c-reset-password-form__confirm-password-container {
  margin-bottom: var(--spacing-10);
}

.c-registration-form__has-read-and-agreed-to-privacy-statement-and-terms-and-conditions-checkbox-container,
.c-registration-form__consent-container {
  margin-bottom: var(--g-spacing);
}

.c-login-form__buttons-links-container,
.c-registration-form__buttons-links-container,
.c-registration-form__is-australian-a-carer-and-over-18-checkbox-container,
.c-registration-form__has-agreed-to-terms-and-conditions-checkbox-container {
  display: flex;
}

.c-registration-form__is-australian-a-carer-and-over-18-checkbox-container {
  margin-bottom: var(--spacing-2);
}

.c-contact-form__submit-button-container,
.c-directory-form__submit-button-container,
.c-forgot-password-form__reset-password-button-container,
.c-login-form__forgot-password-login-container,
.c-reset-password-form__reset-password-button-container {
  margin-left: auto;
  text-align: right;
}

.c-login-form__dont-have-an-account-text-container,
.c-login-form__forgot-password-link,
.c-registration-form__already-have-an-account-text-container,
.c-registration-form__forgot-password-link {
  margin-bottom: var(--spacing-8);
}

.c-login-form__forgot-password-link,
.c-registration-form__forgot-password-link {
  color: var(--color-primary);
  display: block;
  font-weight: 700;
}

.c-login-form__right-container-buttons-links,
.c-registration-form__left-container-buttons-links,
.c-registration-form__right-container-buttons-links {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
}

.c-login-form__left-container-buttons-links {
  display: flex;
}

.c-login-form__right-container-buttons-links,
.c-registration-form__right-container-buttons-links {
  align-items: flex-end;
  display: flex;
}

.c-directory-form__contact-container,
.c-directory-form__contact-email-container,
.c-directory-form__contact-number-container,
.c-directory-form__postcode-container,
.c-directory-form__state-container,
.c-directory-form__state-postcode-container,
.c-registration-form__first-name-container,
.c-registration-form__last-name-container,
.c-registration-form__name-container,
.c-contact-form__name-container {
  display: flex;
  flex: 1;
}

.c-directory-form__contact-number-container,
.c-directory-form__postcode-container,
.c-registration-form__last-name-container,
.c-contact-form__last-name-container {
  padding-left: var(--spacing-3);
  width: 50%;
}

.c-registration-form__first-name-container,
.c-directory-form__state-container,
.c-directory-form__contact-email-container,
.c-contact-form__first-name-container {
  width: 50%;
}

.c-error-message {
  color: var(--red-800);
  font-weight: 700;
  padding: 0.5rem 0.25rem;
}

.c-google-map {
  border: 1px solid var(--grey-100);
  min-height: 40rem;
}

.c-success-message .c-message__text {
  display: none;
}

.cursor {
  cursor: pointer;
}

/* User genreated form buttons */
.c-user-generated-response__container {
  margin-bottom: var(--spacing-12);
  margin-top: 1rem;
}

.c-user-generated-response__add-new-item-button {
  cursor: pointer;
  padding: 0;
}

.c-user-generated-response__input-container,
.c-input--validation-container {
  flex: 1;
}

.c-user-generated-response__add-new-item-button-content-container {
  align-items: center;
  display: flex;
}

.c-user-generated-response__add-new-item-button-icon,
.checkbox-item-delete-button-container button {
  background-color: var(--color-accent);
  border-radius: 50%;
  color: var(--white);
  height: var(--spacing-6);
  margin-right: var(--spacing-2);
  padding: var(--spacing-1);
  width: var(--spacing-6);
}

.c-user-generated-response__save-button,
.c-user-generated-response__discard-button,
.checkbox-item-delete-button-container button {
  color: var(--white);
  border: none;
  border-radius: 10px;
  height: var(--spacing-12);
  font-weight: 700;
  padding: 0 var(--spacing-4);
  width: auto;
}

.c-user-generated-response__save-button *,
.c-user-generated-response__discard-button *,
.checkbox-item-delete-button-container button * {
  font-family: var(--font-header);
}

.c-user-generated-response__save-button .h-hide-visually,
.c-user-generated-response__discard-button .h-hide-visually,
.checkbox-item-delete-button-container button .h-hide-visually {
  clip: inherit;
  clip-path: initial;
  height: auto;
  width: auto;
  position: relative;
}

.c-user-generated-response__save-button svg,
.c-user-generated-response__discard-button svg,
.checkbox-item-delete-button-container button svg {
  display: none;
}

.checkbox-item-delete-button-container {
  display: flex;
  justify-content: flex-end;
}

@media (min-width: 64em) {
  .c-checkbox .checkbox-item-delete-button-container {
    opacity: 0;
    transition: 100ms ease-in-out;
  }

  .c-checkbox:hover .checkbox-item-delete-button-container {
    opacity: 1;
  }
}

.c-user-generated-response__save-button:hover {
  background-color: var(--color-accent);
  box-shadow: 0 0 0 var(--g-spacing-3x-small) var(--color-accent), inset 0 0 0 var(--g-spacing-3x-small) var(--white);
}

.checkbox-item-delete-button-container button {
  margin-left: var(--spacing-4);
}

.c-user-generated-response__discard-button:hover,
.checkbox-item-delete-button-container button:hover {
  background-color: var(--red-800);
  box-shadow: 0 0 0 var(--g-spacing-3x-small) var(--red-800), inset 0 0 0 var(--g-spacing-3x-small) var(--white);
}

.c-user-generated-response__save-button {
  background-color: var(--color-accent);
}

.c-user-generated-response__discard-button,
.checkbox-item-delete-button-container button {
  background-color: var(--red-800);
}

/* Toolkit panel */
.c-toolkit {
  align-items: center;
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: var(--spacing-4);
  right: 0;
  top: 100%;
  transform: translateY(-100%);
  width: 100%;
}

.c-tool-kit__bookmark-button-container {
  border-radius: 50%;
  transition: 200ms ease-in-out;
}

.c-tool-kit__bookmark-button-container:hover {
  box-shadow: 20px 23px 46px #00a4b833;
}

.c-toolkit button {
  border-radius: var(--radius-lg);
  height: var(--spacing-12);
  width: var(--spacing-12);
}

/* Toolkit panel - bookmark button */

.c-toolkit .c-tool-kit__bookmark-button-container button {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}

.c-toolkit .c-tool-kit__bookmark-button-container button svg {
  color: var(--color-primary);
  stroke: var(--white);
  stroke-width: 3;
}

.c-tool-kit__bookmark-button-container .c-button-icon-only--selected svg {
  color: var(--white) !important;
}

.c-toolkit .c-tool-kit__bookmark-button-container button:hover,
.c-toolkit .c-tool-kit__bookmark-button-container button:focus {
  box-shadow: 0 0 0 var(--g-spacing-3x-small) var(--color-primary), inset 0 0 0 var(--g-spacing-3x-small) var(--g-color-white);
}

/* Toolkit panel - generate pdf button */
.c-toolkit .c-tool-kit__generate-pdf-button-container,
.c-toolkit .c-tool-kit__download-pdf-button-container {
  position: relative;
  margin: var(--spacing-1) var(--spacing-2);
}

.c-toolkit .c-tool-kit__generate-pdf-button-container button {
  border-color: var(--blue-900);
}

.c-toolkit .c-tool-kit__generate-pdf-button-container button svg,
.c-tool-kit__download-pdf-button-container button svg {
  position: absolute;
  top: 50%;
  left: 50%;
}

.c-toolkit .c-tool-kit__generate-pdf-button-container button svg {
  height: var(--spacing-8);
  stroke: var(--blue-900);
  transform: translate(-47.5%, -45%);
}

.c-tool-kit__download-pdf-button-container button svg {
  height: var(--spacing-11);
  transform: translate(-49.5%, -47.5%);
  width: var(--spacing-11);
}

.c-toolkit .c-tool-kit__generate-pdf-button-container button:hover,
.c-toolkit .c-tool-kit__generate-pdf-button-container button:focus,
.c-toolkit .c-tool-kit__download-pdf-button-container button:hover,
.c-toolkit .c-tool-kit__download-pdf-button-container button:focus {
  box-shadow: 0 0 0 var(--g-spacing-3x-small) var(--blue-900), inset 0 0 0 var(--g-spacing-3x-small) var(--g-color-white);
}

@media (min-width: 768px) {
  .c-toolkit {
    background-color: transparent;
    flex-direction: column;
    padding: 0;
    right: var(--spacing-4);
    top: var(--spacing-104);
    width: auto;
  }
}

@media (min-width: 1024px) {
  .c-toolkit .c-tool-kit__generate-pdf-button-container button svg {
    transform: translate(-42.5%, -37.5%);
  }

  .c-tool-kit__download-pdf-button-container button svg {
    transform: translate(-42.5%, -42.5%);
  }
}

/* Dialog popups */
.c-dialog,
.c-dialog__header {
  background-color: var(--white);
  border-color: var(--color-accent);
}

.c-dialog__close-icon {
  background-color: var(--white);
}

/* Video snippet */
.c-video-snippet__video {
  width: 100%;
}

/* All elements to recieve color adjustment for print */
* {
  -webkit-print-color-adjust: exact;
}

.calendar {
  border-radius: 15px;
  border: 1px solid var(--color-whisper);
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  min-width: 60rem;
}

.calendar-day-container {
  text-align: right;
  width: 14.28%;
}

.calendar-day-container__card {
  background-color: white;
  cursor: pointer;
  position: relative;
  min-height: 10rem;
  padding: 0.5rem;
  border: 1px solid var(--color-whisper);
  transition: 150ms ease-in-out;
  width: 100%;
}

.calendar-day-container__card:hover {
  background-color: var(--grey-50);
}

.calendar-day-container > .day {
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
}

.calendar-day-container > .entry {
  align-items: center;
  background: var(--color-whisper);
  border-bottom: 1px solid var(--white);
  border-left: 3px solid var(--color-secondary);
  display: flex;
  padding: 0 0.5rem;
  transition: 0.2s;
}

.calendar-day-container > .entry img {
  margin-right: 0.35rem;
}

.calendar-day__day {
  color: #000;
  margin-bottom: 0;
  padding: 0 0.5rem;
  text-align: right;
}

.entry--morning:hover + .entry--tooltip,
.entry--afternoon:hover + .entry--tooltip,
.entry--evening:hover + .entry--tooltip {
  display: block;
}

.entry--tooltip {
  display: none;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  top: -30px;
  min-width: 115%;
  left: -7.5%;
  padding: 0.6rem;
  border-radius: 16px;
  z-index: 9999;
  background: white;
}

.entry--tooltip--bottom {
  top: 100%;
}

.entry--tooltip > div {
  gap: 10px;
}

.entry--tooltip > div > p {
  margin-bottom: 0px;
  font-weight: bold;
  font-size: 18px;
}

.mood--value {
  margin-right: 0.35rem;
  background: #262d5d;
  border-radius: 50px;
  font-size: 12px;
  width: 17px;
  height: 17px;
  color: white;
  align-items: center;
  display: flex;
  justify-content: center;
}

.calendar--tooltip--value {
  text-align: center;
  font-size: 17px;
}

.c-calendar-header {
  grid-template-columns: repeat(3, 1fr);
  display: grid;
  margin-bottom: var(--spacing-8);
}

.c-calendar-header--button-container > button {
  float: right;
}

.calendar-day-container .entry--morning {
  border-left-color: var(--color-accent);
}

.calendar-day-container .entry--evening {
  border-left-color: var(--color-primary);
}

.c-contact-form__email-container .entry--afternoon {
  border-left-color: #f19d58;
}

.calendar-day-container > .entry:hover {
  background: #feb800;
}

.c-resource-container {
  display: flex;
  border: 3px solid var(--cerulean-blue);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 3px solid #2251ac;
  border-radius: 10px;
}

.c-resource-image {
  height: 100%;
  min-width: 100px;
  border-radius: 10px 0px 0px 10px;
}

.c-resource-details-container {
  padding: 1rem;
}

.c-resource-type {
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--cerulean-blue);
  text-align: left;
  color: #2251ac;
}

.c-resource-title {
  color: #262d5d;
  font: normal normal 900 18px/60px;
}

.list-view-tag-list {
  display: flex;
  margin: 0;
  padding: 1rem 0;
}

.list-view-tag {
  padding: 0.85rem 1rem;
  border: 3px solid var(--cerulean-blue);
  background: #ffffff;
  border: 3px solid #2251ac;
  border-radius: 10px;
  color: #2251ac;
  cursor: pointer;
  font-weight: 800;
  margin: 0 0.5rem 0 0;
}

.list-view-tag.active {
  border: 3px solid #2251ac;
  background: #2351ad !important;
  color: white !important;
}

.c-avatar {
  background-color: var(--color-primary);
}

.c-avatar img {
  width: 100%;
}

.list-view-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem;
  padding: 0 0 2rem 0;
}

.list-view__item {
  padding: 0 1rem;
  margin: 0 0 1rem 0;
  width: 100%;
}

.list-view-displayed-results {
  color: var(--color-secondary);
  font-weight: 800;
  padding: 1rem 0;
}

.list-view__view-more {
  text-align: center;
}

.list-view__item {
  width: 100%;
}

@media (min-width: 64em) {
  .list-view__item {
    width: 100%;
  }

  .list-view__item--2 {
    width: 50%;
  }

  .list-view__item--3 {
    width: 33.333%;
  }

  .list-view__item--4 {
    width: 25%;
  }
}

.list-view-container._2_elements {
  grid-template-columns: repeat(2, 1fr);
}

.list-view-container._3_elements {
  grid-template-columns: repeat(3, 1fr);
}

.list-view-container._5_elements {
  grid-template-columns: repeat(5, 1fr);
}

.list-view-container._6_elements {
  grid-template-columns: repeat(6, 1fr);
}

.qa-content-snippet-id-26066 {
  display: none;
}

.ek-container-15635:has(.c-checkbox > .c-checkbox__input#\33 590:checked) > .qa-content-snippet-id-26066 {
  display: block;
}

fieldset:has(> .c-checkbox > .c-checkbox__input#\33 582:checked) > .c-checkbox:has(> .c-checkbox__input#\33 591),
fieldset:has(> .c-checkbox > .c-checkbox__input#\33 583:checked) > .c-checkbox:has(> .c-checkbox__input#\33 591),
fieldset:has(> .c-checkbox > .c-checkbox__input#\33 584:checked) > .c-checkbox:has(> .c-checkbox__input#\33 591),
fieldset:has(> .c-checkbox > .c-checkbox__input#\33 585:checked) > .c-checkbox:has(> .c-checkbox__input#\33 591),
fieldset:has(> .c-checkbox > .c-checkbox__input#\33 586:checked) > .c-checkbox:has(> .c-checkbox__input#\33 591),
fieldset:has(> .c-checkbox > .c-checkbox__input#\33 587:checked) > .c-checkbox:has(> .c-checkbox__input#\33 591),
fieldset:has(> .c-checkbox > .c-checkbox__input#\33 588:checked) > .c-checkbox:has(> .c-checkbox__input#\33 591),
fieldset:has(> .c-checkbox > .c-checkbox__input#\33 589:checked) > .c-checkbox:has(> .c-checkbox__input#\33 591),
fieldset:has(> .c-checkbox > .c-checkbox__input#\33 590:checked) > .c-checkbox:has(> .c-checkbox__input#\33 591) {
  pointer-events: none;
  color: var(--color-border);
}

.c-checkbox:has(~ .c-checkbox > .c-checkbox__input#\33 591:checked) {
  pointer-events: none;
  color: var(--color-border);
}

.c-color-picker-preview__button {
  width: 2.75rem;
  height: 2.75rem;
}

.container > .items-center.flex.mb-8.no-print > button:nth-child(2) {
  opacity: 1;
}

.container:has(#question-snippet-id-25743 ~ .c-input-field input[value='']):has(#question-snippet-id-25741
    ~ fieldset
    > .c-checkbox
    > .c-checkbox__input:checked#\33386
    ~ label)
  > .items-center.flex.mb-8.no-print
  > button:nth-child(2) {
  pointer-events: none;
  opacity: 0.5 !important;
}

.additional-controls-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.additional-controls-calendar-buttons {
  display: flex;
  gap: 10px;
}

.c-text-snippet__link--target {
  align-items: center;
  display: inline-flex;
}

.c-text-snippet__link--target span {
  margin-bottom: 0;
}

.c-text-snippet__link--target svg {
  margin-right: 0.25rem;
}

@media (max-width: 64em) {
  .c-kanzi {
    display: none !important;
  }
}

.c-community-embargo-toggle-switch {
  display: flex;
  gap: 10px;
  align-items: flex-end;
}

/*
.c-icon {
  height: var(--g-spacing-large);
  width: var(--g-spacing-large);
} */


