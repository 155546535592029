.c-user-details-header {
  display: flex;
}

.c-user-details-header__avatar {
  margin-right: var(--g-spacing-small);
  position: relative;
}

.c-user-details-header__icon {
  align-items: center;
  background-color: var(--color-accent);
  border-radius: 50%;
  color: var(--g-color-black);
  display: flex;
  height: 1rem;
  justify-content: center;
  padding: 2px;
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-40%, -15%);
  width: 1rem;
}

.c-user-details-header__details {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.c-user-details-header__avatar .c-avatar {
  height: var(--g-spacing-3x-large);
  width: var(--g-spacing-3x-large);
}

.c-user-details-header__avatar .c-avatar,
.c-user-details-header__details .c-user-details-header__description {
  margin-bottom: 0;
}

.c-user-details-header__display-name {
  color: var(--color-secondary);
  font-size: var(--g-font-size-2x-large);
  font-weight: 600;
  line-height: 1;
  margin-bottom: 0;
}

.c-user-details-header__description {
  color: #9a9ab2;
  font-size: var(--g-font-size-small);
  margin-top: -8px;
}
