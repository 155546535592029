.c-messages-page__container {
  max-width: var(--container-width-sm);
  margin: 0 auto;
  padding: 0;
  width: 90%;
}

.c-messages-page__container h2 {
  font-size: var(--g-font-size-large);
  margin-bottom: 1rem;
}

.c-messages-page__search {
  align-items: center;
  display: flex;
  margin-bottom: var(--g-spacing-2x-large);
}

.c-messages-page__search .c-search-input {
  margin-right: var(--g-spacing);
}

.c-messages-page__container .c-list-card__message {
  margin: 0 1rem;
}

.c-messages-page__container .c-list-card__unread-count {
  align-items: center;
  background-color: #d85e2e;
  border-radius: 50%;
  color: var(--g-color-white);
  display: flex;
  height: var(--g-font-size-large);
  font-size: 0.65rem;
  font-weight: 600;
  justify-content: center;
  width: var(--g-font-size-large);
}
