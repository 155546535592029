.c-create-post-page__container {
  max-width: var(--container-width-sm);
  margin: 0 auto;
  width: 90%;
}

.c-create-post-page__card {
  align-items: center;
  background-color: var(--g-color-white);
  border: 1px solid var(--color-athens);
  border-radius: 10px;
  display: flex;
  padding: var(--g-spacing);
}

.c-create-post__label {
  margin-bottom: 0;
  margin-right: auto;
}

.c-create-post__option {
  align-items: center;
  background-color: var(--g-color-white);
  border: 3px solid var(--color-primary);
  border-radius: 8px;
  color: var(--color-primary);
  cursor: pointer;
  display: flex;
  margin-left: var(--g-spacing-x-small);
  padding: var(--g-spacing-x-small) var(--g-spacing);
}

.c-create-post__option--active {
  background-color: var(--color-primary);
  color: var(--g-color-white);
}

.c-create-post__option span {
  display: block;
  font-weight: 600;
  margin: 0;
}

.c-create-post-page__container .c-textarea,
.c-create-post-page__container .c-input {
  margin-bottom: var(--g-spacing-large);
  padding: var(--g-spacing);
}

.c-create-post-page__container .c-textarea:hover,
.c-create-post-page__container .c-input:hover {
  border: none !important;
}

.c-create-post-page__container #post-title {
  font-weight: 800;
}

.c-create-post-page__container .c-label {
  display: none;
}

.c-create-post-page__container .c-button--icon-only {
  border-radius: 50%;
  display: flex;
  margin-left: auto;
  padding: var(--g-spacing-large);
  position: relative;
}

.c-create-post-page__container .c-button--icon-only svg {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.c-create-post-page__submit {
  margin: var(--g-spacing-large) 0 var(--g-spacing-2x-large) 0;
  text-align: center;
}

.c-create-post-page__submit .c-button__text {
  font-size: 1.25rem;
  font-weight: 400;
}

.c-poll-item {
  margin-bottom: var(--g-spacing-large);
  position: relative;
  width: 100%;
}

.c-poll-item input {
  margin-bottom: 0 !important;
}

.c-poll-item .c-input-field {
  margin-bottom: 0;
}

.c-poll-item button {
  cursor: pointer;
  display: flex;
  position: absolute;
  padding: 0;
  right: var(--g-spacing-x-small);
  top: 50%;
  transform: translateY(-50%);
}

.c-poll-item button svg {
  width: var(--g-spacing-2x-large);
}

.c-poll-item button svg path {
  fill: #2251ac;
  stroke: var(--g-color-white);
}

.c-create-post-date {
  align-items: stretch;
  display: flex;
  margin-bottom: var(--g-spacing-large);
  width: 100%;
}

.c-create-post-date__switch .c-label {
  display: inline-block;
}

.c-create-post-date .h-spacing-small {
  margin: 0;
}

.c-create-post-date .c-toggle-switch {
  height: calc(var(--g-spacing-x-large) + 5px);
  position: relative;
  top: 0;
  width: var(--g-spacing-4x-large);
}

.c-create-post-date .c-toggle-switch__wrapper {
  width: auto;
}

.c-create-post-date .c-create-post-date__toggle {
  flex: 1;
  margin-right: var(--g-spacing-x-large);
}

.c-create-post-date .ant-picker {
  padding: 0.5rem;
}

.c-create-post-date .ant-picker,
.c-create-post-date .ant-picker-focused,
.c-create-post-date .ant-picker:hover {
  border: none !important;
  box-shadow: none !important;
}

.c-create-post-date .c-toggle-switch__wrapper {
  display: flex;
}

.c-create-post-share {
  cursor: pointer;
  width: 100%;
}

.c-create-post-share span {
  margin-bottom: 0;
}

.c-create-post__error {
  background-color: var(--g-color-white);
  border: 2px solid var(--color-primary);
  border-radius: 10px;
  display: flex;
  margin: var(--g-spacing) 0;
  padding: var(--g-spacing);
}

.c-create-post__error ul {
  margin: 0;
}

.c-create-post__error li {
  line-height: 1.5;
  margin-bottom: 0;
  margin-left: 0;
}

.c-create-post__error svg {
  height: var(--g-spacing-x-large);
  margin-right: var(--g-spacing-small);
  stroke: var(--color-primary);
  stroke-width: 1.8;
  width: var(--g-spacing-x-large);
}


.CalendarDay__selected .c-date-range__day {
  background-color: var(--color-primary);
  color: var(--g-color-white);
}