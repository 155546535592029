
.c-display-name-generator .c-input-field {
  margin-bottom: 0 !important;
}

.c-display-name-generator button:disabled {
  background-color: var(--color-athens);
  cursor: not-allowed;
}

