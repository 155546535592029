/* Reveal accordions (with icons) */
.c-event-trigger {
  background-color: var(--color-primary);
  cursor: pointer;
}

.c-event-trigger--text-and-icon {
  background-color: var(--white);
  border-radius: 8px;
  cursor: pointer;
  color: var(--color-secondary);
  display: flex;
  font-family: var(--font-header);
  padding: 1.2rem;
  text-align: left;
  width: 100%;
}

.c-event-trigger--text-and-icon[aria-expanded='true'] {
  border-bottom: 1px solid var(--color-whisper);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.c-event-trigger--text-and-icon .c-event-trigger__title {
  color: inherit;
  flex: 1;
  margin: 0;
  color: inherit;
  font-size: var(--g-font-size-large);
  font-weight: 800;
}

.c-accordion__details {
  background-color: var(--white);
  border-radius: 0 0 8px 8px;
  padding: 1.2rem;
  opacity: 0;
  animation: fadeUp 500ms cubic-bezier(0.23, 1, 0.32, 1) forwards;
}

.c-event-trigger__icon-container {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0;
}

.c-event-trigger__icon {
  background-color: var(--white);
  border-radius: 50%;
  color: var(--color-secondary);
  height: 1.5rem;
  width: 1.5rem;
}

/* Popup text only */
.c-event-trigger--text-only {
  border-radius: 50px;
  display: block;
  font-weight: 700;
  margin: 0 auto;
  min-height: 3.5rem;
  padding: 0 2rem;
  width: auto;
}
