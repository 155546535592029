.c-conversation-page__container {
  max-width: var(--container-width-xs);
  margin: 0 auto;
  padding: var(--g-spacing-3x-large) 0;
  width: 90%;
}

.c-conversation-page__title {
  color: var(--g-color-black);
  margin-bottom: var(--g-spacing-2x-large);
}

.c-conversation-page__title h1 {
  color: var(--color-primary);
  font-size: var(--g-font-size-4x-large);
}

.c-conversation-page__title * {
  font-family: var(--font-body);
}

.c-conversation-page__conversation {
  border-bottom: 1px solid #e5edf0;
  border-top: 1px solid #e5edf0;
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: var(--g-spacing);
  max-height: 40vh;
  overflow-y: auto;
  padding-bottom: var(--g-spacing);
  padding-right: var(--g-spacing-x-large);
  padding-top: var(--g-spacing);
}

.c-conversation-page__conversation::-webkit-scrollbar {
  width: var(--g-spacing-2x-small);
}

.c-conversation-page__conversation::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #d9e4ea;
  border-radius: 10px;
}

.c-conversation-page__conversation::-webkit-scrollbar-thumb {
  background-color: #2251ac;
  border-radius: 10px;
}

.c-conversation-page__form {
  display: flex;
  flex-direction: column;
}

.c-conversation-page__form button {
  margin-top: var(--g-spacing);
  margin-left: auto;
}

.c-conversation-page__form input {
  border-color: var(--g-color-white);
  margin-right: var(--g-spacing);
}

.c-conversation-page__empty-message {
  margin: var(--g-spacing-2x-large) 0;
}
