.c-profile-page__container {
  max-width: var(--container-width-sm);
  margin: 0 auto;
  width: 90%;
}

.c-toggle-switch {
  margin: 0 auto;
}

.c-toggle-switch__slider::before {
  left: 0;
}
