.c-online-moderators {
  display: flex;
  flex-direction: column;
}

.c-online-moderators__count {
  background-color: var(--g-color-white);
  border-radius: 10px;
  display: inline-block;
  font-weight: 600;
  font-size: var(--g-font-size-small);
  padding: var(--g-spacing-2x-small) var(--g-spacing-small);
}

.c-online-moderators__list {
  align-items: center;
  display: flex;
  margin: 0;
  overflow-x: auto;
}

.c-online-moderators__list li {
  margin: 0 var(--g-spacing-2x-large) 0 0;
}
