.c-community-member-card {
  position: relative;
}

.c-community-member-card__toggle {
  position: absolute;
  top: 50%;
  right: 3rem;
  transform: translateY(-50%);
  width: auto;
}

.c-community-member-card__toggle .c-label {
  display: flex;
}

.c-community-member-card__toggle .c-toggle-switch {
  margin-left: .5rem;
}