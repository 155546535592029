/* Table */
.c-table-container {
  width: 100%;
}

.c-table__tbody-tr {
  border: none;
}

.c-table__tbody-td,
.c-table__thead-th {
  padding: var(--g-spacing) var(--g-spacing-x-small);
}

.c-table__tbody-tr:hover {
  background-color: var(--white);
  border: none !important;
}

.c-table__tbody-tr:nth-child(even):hover {
  background-color: var(--blue-100);
}

.c-table-form .c-table__tbody-td:first-of-type {
  width: 100% !important;
}

@media (min-width: 1000px) {
  .c-table-form .c-table__tbody-td:first-of-type {
    width: 40% !important;
  }
}

@media only screen and (min-width: 1001px) {
  .c-table__tbody-tr:hover .c-table__tbody-td:first-child>.c-table__tbody__td-content:first-child {
    border-left-color: var(--color-primary);
  } 
}
