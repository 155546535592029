:root {
  /* COLORS */
  --color-border: rgba(0, 0, 0, 0.25);
  --color-button-hover: salmon;
  --color-header: #333;
  --color-link: #88e;

  --color-accent-200: #f4f8f8;
  --color-accent-300: #c9eeed;
  --color-accent-400: #ade2e0;
  --white: white;
  --black: #1b1a1f;

  --grey-50: #f4f5f8;
  --grey-100: #f0f0f7;
  --grey-200: #c7ccd8;
  --grey-300: #7a7a7a;
  --grey-400: #dfdfec;
  --grey-500: #d8dceb;
  --green-200: #00a4b833;
  --green-300: #80dad1;
  --green-400: #6dc7be;
  --green-500: #5a9d95;
  --green-900: #48756f;
  --blue-900: #1b1b46;
  --blue-800: #23527a;
  --blue-700: #004e70;

  --blue-500: #80a0df;
  --blue-400: #c1d6ff;

  --blue-300: #4679c2;
  --blue-200: #e6f0f3;
  --blue-100: #f2f7f9;

  --red-700: #e83c48;
  --red-800: #c72530;
  --red-900: #b21c27;
  --pink-400: #d12d91;
  --pink-300: #ff7e98;

  --yellow-200: #ffe3ab;
  --yellow-300: #ffc858;
  --yellow-400: #feb800;
  --yellow-500: #f19d58;
  --yellow-600: #dc7d3d;

  --orange-200: #ffd88a;
  --orange-300: #f9be74;
  --orange-400: #f6b169;
  --orange-500: #f19d58;

  --color-primary: #2251ac;
  --color-secondary: #262d5d;
  --color-accent: #feb800;
  --color-accent-light: #ffc858;
  --color-accent-lighter: #ffe3ab;

  --color-whisper: var(--grey-100);
  --color-athens: var(--grey-400);

  /* FONTS */
  --font-body: 'freight-sans-pro', sans-serif;
  --font-header: 'navigo', sans-serif;

  /* SPACING */
  --spacing-base: 0.25rem;
  --spacing-0-5: calc(var(--spacing-base) / 2);
  --spacing-1: calc(var(--spacing-base) * 1);
  --spacing-1-5: calc(var(--spacing-base) * 1.5);
  --spacing-2: calc(var(--spacing-base) * 2);
  --spacing-3: calc(var(--spacing-base) * 3);
  --spacing-4: calc(var(--spacing-base) * 4);
  /* 1rem */
  --spacing-5: calc(var(--spacing-base) * 5);
  --spacing-6: calc(var(--spacing-base) * 6);
  --spacing-7: calc(var(--spacing-base) * 7);
  --spacing-8: calc(var(--spacing-base) * 8);
  /* 2rem */
  --spacing-9: calc(var(--spacing-base) * 9);
  --spacing-10: calc(var(--spacing-base) * 10);
  --spacing-11: calc(var(--spacing-base) * 11);
  --spacing-12: calc(var(--spacing-base) * 12);
  /* 3rem */
  --spacing-13: calc(var(--spacing-base) * 13);
  --spacing-14: calc(var(--spacing-base) * 14);
  --spacing-15: calc(var(--spacing-base) * 15);
  --spacing-16: calc(var(--spacing-base) * 16);
  /* 4rem */
  --spacing-17: calc(var(--spacing-base) * 17);
  --spacing-18: calc(var(--spacing-base) * 18);
  --spacing-19: calc(var(--spacing-base) * 19);
  --spacing-20: calc(var(--spacing-base) * 20);
  /* 5rem */
  --spacing-22: calc(var(--spacing-base) * 22);
  --spacing-23: calc(var(--spacing-base) * 23);
  --spacing-24: calc(var(--spacing-base) * 24);
  /* 6rem */
  --spacing-26: calc(var(--spacing-base) * 26);
  --spacing-28: calc(var(--spacing-base) * 28);
  --spacing-32: calc(var(--spacing-base) * 32);
  /* 8rem */
  --spacing-34: calc(var(--spacing-base) * 34);
  --spacing-36: calc(var(--spacing-base) * 36);
  --spacing-38: calc(var(--spacing-base) * 38);
  --spacing-40: calc(var(--spacing-base) * 40);
  /* 10rem */
  --spacing-42: calc(var(--spacing-base) * 42);
  --spacing-48: calc(var(--spacing-base) * 48);
  /* 11rem */

  --spacing-52: calc(var(--spacing-base) * 52);
  /* 13rem */
  --spacing-60: calc(var(--spacing-base) * 60);
  /* 15rem */
  --spacing-64: calc(var(--spacing-base) * 64);
  /* 16rem */
  --spacing-72: calc(var(--spacing-base) * 72);
  /* 18rem */
  --spacing-80: calc(var(--spacing-base) * 80);
  /* 20rem */
  --spacing-88: calc(var(--spacing-base) * 88);
  /* 22rem */
  --spacing-92: calc(var(--spacing-base) * 92);
  --spacing-96: calc(var(--spacing-base) * 96);
  /* 24rem */
  --spacing-98: calc(var(--spacing-base) * 98);
  --spacing-104: calc(var(--spacing-base) * 104);
  /* 25rem */
  --spacing-112: calc(var(--spacing-base) * 112);
  /* 26rem */
  --spacing-176: calc(var(--spacing-base) * 176);
  /* 44rem */

  /* TYPOGRAPHY */
  --fs-base: 62.5%;
  --fs-normal: 1.6rem;
  --header-weight: bold;
  --header-lh: 1.5;
  --header-ls: 0.25rem;
  --header-align: center;
  --h1-size: 6rem;
  --h2-size: 4rem;
  --h3-size: 2rem;
  --h4-size: 1.8rem;
  --lh-normal: 1.4;
  --ls-normal: 0.02rem;
  --p-size: 1.6rem;
  --p-lh: 1.4;
  --p-ls: 0.2rem;
  --p-mb: var(--spacing-2);

  /* BORDERS */
  --border: 1px solid var(--color-border);
  --radius: 4px;
  --radius-sm: 2px;
  --radius-md: 6px;
  --radius-lg: 10px;

  /* SHADOWS */
  --box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);

  /* CONTAINERS */
  --container-width-xs: 600px;
  --container-width-sm: 800px;
  --container-width: 1200px;
  --container-lg: 1600px;
  --container-xl: 2454px;

  --g-color-disabled: #c9d0d2;

  --g-range-input-track-base-color: var(--grey-100);
  --g-range-input-track-highlighted-color: var(--color-primary);
  --g-range-thumb-color: var(--color-primary);
}

/* Buttons */
.c-button {
  align-items: center;
  border-width: 3px;
  border-radius: 10px;
  display: inline-flex;
  justify-content: center;
  font-family: var(--font-body);
}

.c-button:disabled {
  background-color: var(--color-athens);
  pointer-events: none;
}

.c-button:focus {
  border-width: 3px;
  box-shadow: none !important;
}

.c-button--primary {
  background-color: var(--color-primary);
  color: var(--g-color-white);
}

.c-button--primary:hover {
  background-color: var(--color-secondary);
}

.c-button--secondary {
  /* background-color: transparent; */
  border: 3px solid var(--color-primary);
  color: var(--color-primary);
}

.c-button--secondary:focus {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  box-shadow: 0 0 0 1px var(--color-primary), inset 0 0 0 2px var(--g-color-white);
}

.c-button--secondary:disabled {
  border-color: var(--color-athens);
  color: var(--color-secondary);
}

.c-button--cta {
  background-color: var(--color-accent);
}

.c-button--secondary:hover:enabled {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: var(--g-color-white);
}

.c-button--tertiary:hover:enabled {
  background-color: var(--color-primary);
}

.c-button__text {
  justify-content: center;
}

.c-button__text span {
  margin-bottom: 0;
}

.c-button--primary:focus {
  box-shadow: 0 0 0 var(--g-spacing-3x-small) var(--color-primary), inset 0 0 0 var(--g-spacing-3x-small) var(--g-color-white);
}

/* Breadcrumbs */
.c-breadcrumbs {
  display: flex;
  justify-content: flex-end;
  padding: var(--g-spacing) 0;
}

.c-breadcrumb-item {
  color: var(--color-secondary);
  margin-bottom: 0;
}

/* Checkboxes */
.c-checkbox__icon-container {
  background-color: var(--g-color-white) !important;
  border-color: var(--color-primary);
  margin-bottom: 0;
}

.c-checkbox__input:checked+.c-checkbox__label .c-checkbox__icon-container {
  background-color: var(--color-primary) !important;
}

/* Dialog */
.c-dialog-overlay {
  padding: 5% 0;
}

.c-dialog {
  border-radius: 10px;
  padding: 2rem;
  overflow: hidden;
}

.c-dialog__header {
  background-color: transparent;
  justify-content: left;
}

.c-dialog__title {
  color: var(--color-secondary);
  font-family: var(--font-body);
  font-size: var(--g-font-size-3x-large);
}

.c-dialog__content {
  color: #707070;
  overflow: auto;
  text-align: left;
}

.c-dialog__content * {
  scrollbar-width: thin;
  scrollbar-color: var(--color-primary) auto;
}

.c-dialog__content::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.c-dialog__content::-webkit-scrollbar-track {
  background: var(--color-whisper);
}

.c-dialog__content::-webkit-scrollbar-thumb {
  background-color: var(--color-primary);
  border-radius: 40px;
}

.c-dialog__buttons {
  justify-content: flex-end;
}

/* CSS Styles */
.ek-focus,
.ek-emphasise,
.ek-prompt,
.ek-rationale,
.ek-info,
.ek-instruction {
  align-items: center;
  background-color: var(--color-primary);
  border: 2px solid var(--color-secondary);
  border-radius: 10px;
  color: var(--white);
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem !important;
}

.ek-info,
.ek-instruction {
  background-color: var(--color-accent) !important;
  border: 2px solid var(--yellow-500);
  color: var(--color-black);
}

.ek-rationale {
  background-color: var(--yellow-500);
  border-color: var(--yellow-600);
  color: var(--color-black);
}

.ek-emphasise {
  background-color: var(--color-secondary);
  border-color: var(--color-primary);
}

.ek-focus {
  background-color: var(--white);
  border-color: var(--color-primary);
  color: var(--color-secondary);
}

.ek-focus .c-snippet__icon,
.ek-emphasise .c-snippet__icon,
.ek-prompt .c-snippet__icon,
.ek-rationale .c-snippet__icon,
.ek-info .c-snippet__icon,
.ek-instruction .c-snippet__icon {
  margin-bottom: 1rem;
  max-width: 3.5rem;
}

@media (min-width: 64em) {

  .ek-focus,
  .ek-emphasise,
  .ek-prompt,
  .ek-rationale,
  .ek-info,
  .ek-instruction {
    flex-direction: row;
  }

  .ek-focus .c-snippet__icon,
  .ek-emphasise .c-snippet__icon,
  .ek-prompt .c-snippet__icon,
  .ek-rationale .c-snippet__icon,
  .ek-info .c-snippet__icon,
  .ek-instruction .c-snippet__icon {
    margin-bottom: 0;
    margin-right: 1.5rem;
  }
}

/* Checklist, Radiolist, Table radiolist */
.ek-checklist,
.ek-radiolist,
.ek-table-radiolist,
.ek-checklist .ek-section,
.ek-radiolist .ek-section,
.ek-table-radiolist .ek-section {
  background-color: transparent;
  border: none;
  padding: 0;
}

.ek-checklist .c-checkbox__label,
.ek-radiolist .c-radio-button__label,
.ek-table-radiolist .c-table__tbody-tr {
  background-color: var(--g-color-white);
  border: 1px solid var(--color-athens);
  border-radius: 10px;
  margin-bottom: 0.5rem;
  padding: 1rem;
  width: 100%;
}

.ek-checklist .c-checkbox__icon-container {
  border-radius: 50%;
}

.ek-checklist .c-question-snippet__question strong,
.ek-radiolist .c-question-snippet__question strong,
.ek-table-radiolist .ek-text-snippet strong {
  color: var(--color-primary);
}

.ek-checklist .c-checkbox__input:checked+.c-checkbox__label,
.ek-radiolist .c-radio-button__item:checked+.c-radio-button__label {
  background-color: var(--color-primary);
  color: var(--g-color-white);
}

.ek-checklist .c-checkbox__input:checked+.c-label .c-checkbox__icon-container,
.ek-radiolist .c-radio-button__item:checked+.c-radio-button__label::before {
  background-color: var(--color-secondary);
  border-color: var(--color-secondary);
  color: var(--g-color-white);
}

.ek-radiolist .c-radio-button__item:checked+.c-radio-button__label::before {
  border-color: var(--g-color-white);
}

.ek-radiolist .c-radio-button__item~label::before,
.ek-table-radiolist .c-radio-button__item~label::before {
  border: 2px solid var(--color-athens);
}

.ek-table-radiolist .c-radio-button__item:checked+.c-radio-button__label::before {
  border-color: var(--color-primary);
}

.ek-checklist .c-checkbox__input:focus+.c-label .c-checkbox__icon-container {
  outline: none;
}

.ek-checklist .c-question-snippet__question p,
.ek-radiolist .c-question-snippet__question p,
.ek-table-radiolist .ek-text-snippet p {
  font-size: var(--g-font-size-x-large);
  line-height: 1.4;
  font-weight: 600;
}

.ek-radiolist .c-question-snippet__question {
  margin-bottom: var(--g-font-size-x-large);
}

.ek-table-radiolist .c-table__thead {
  background-color: var(--color-primary);
}

.ek-table-radiolist .c-table__thead * {
  color: var(--g-color-white);
}

.ek-table-radiolist table {
  border-collapse: separate;
  border-spacing: 0 1rem;
}

.ek-table-radiolist .c-question-snippet__question p {
  font-weight: 500;
  line-height: 1.4;
  margin-bottom: 0;
}

.ek-table-radiolist tr {
  border-radius: 10px;
}

.ek-table-radiolist tr td:first-child,
.ek-table-radiolist tr th:first-child {
  border-radius: 10px 0 0 10px;
}

.ek-table-radiolist tr td:last-child,
.ek-table-radiolist tr th:last-child {
  border-radius: 0 10px 10px 0;
}

.ek-table-radiolist .c-table__tbody-td {
  vertical-align: middle;
}

.ek-table-radiolist .c-question-snippet {
  margin-bottom: 0;
}

.ek-table-radiolist .c-table__thead-th {
  font-size: var(--g-font-size);
  padding: var(--g-spacing) !important;
}

.ek-table-radiolist .c-table__tbody-td {
  padding: var(--g-spacing);
}

.ek-table-radiolist .c-table__tbody-tr:nth-child(even):hover {
  background-color: var(--g-color-white);
}

@media(min-width: 64em) {
  .ek-table-radiolist .l-layout.l-layout--gap-none div:first-child {
    width: 100%;
  }
}

/* Reset section */
.ek-reset-section {
  background: transparent;
  border: none;
  padding: 0;
}

/* Question text */
.ek-question-text {
  background: transparent;
  border: none;
  line-height: 1.4;
  font-weight: 600;
  padding: 0;
}

.ek-question-text p {
  font-size: var(--g-font-size-x-large);
  line-height: 1.4;
}

.ek-question-text strong {
  color: var(--color-primary);
}

/* Datepicker */
.netfront-ui-external-antd {
  background-color: transparent;
}

.c-button--secondary>.c-button__spinner>.c-spinner__icon {
  border-color: var(--color-primary) var(--color-primary) var(--color-primary) transparent;
}

.c-button--secondary:focus>.c-button__spinner>.c-spinner__icon {
  border-color: #ffffff #ffffff #ffffff transparent;
}

/* Accordions */
.c-accordion__item-container:last-child {
  border-bottom: none;
}

.c-accordion__item-label:not(.c-accordion__item-child-container .c-accordion__item-label) {
  color: inherit;
  font-size: var(--g-font-size-large);
  font-weight: 800;
}

.c-accordion__item-container {
  padding: var(--g-spacing);
}

.c-accordion__item-dropdown-icon:hover,
.c-accordion__item-dropdown-icon:focus {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}

.c-summary-feedback-multi-response {
  margin-bottom: 2rem;
}

.c-summary-feedback-multi-response__container {
  display: flex;
  margin: 0 -0.5rem;
}

.c-summary-feedback-multi-response__category {
  padding: 0 0.5rem;
  width: 50%;
}

.c-summary-feedback-multi-response__entry {
  align-items: center;
  display: flex;
}


.c-summary-feedback-multi-response__entry input {
  margin-right: 0.5rem;
}

.c-summary-feedback-multi-response__summary {
  font-weight: 800;
  font-size: 1.35rem;
  margin-bottom: 0.5rem;
}

.c-summary-feedback-multi-response__input {
  align-items: center;
  background-color: var(--color-whisper);
  border-radius: 10px;
  display: flex;
  padding: 0.5rem;
  margin-bottom: 1rem;
}

.c-summary-feedback-multi-response__input label {
  position: absolute;
}

.c-summary-feedback-multi-response__input input:disabled {
  background-color: transparent !important;
  border-color: transparent !important;
}

.c-summary-feedback-multi-response__category-head {
  background-color: var(--color-primary);
  border-radius: 10px;
  color: #fff;
  font-weight: 800;
  margin-bottom: 1rem;
  padding: 0.5rem;
  text-align: center;
}

.c-summary-feedback-multi-response__controls {
  display: flex;
}

.c-summary-feedback-multi-response__category+.c-summary-feedback-multi-response__category .c-summary-feedback-multi-response__input {
  background-color: #fff1ca !important;
}

.c-summary-feedback-multi-response__controls .c-button-icon-only {
  /* background-color: #fff; */
  border-radius: 50%;
  cursor: pointer;
  margin-left: 0.25rem;
  padding: 0.5rem;
}

/* .c-summary-feedback-multi-response__controls .c-button-icon-only svg {
  color: var(--color-primary);
  stroke: var(--color-primary);
} */

.c-summary-feedback-multi-response__category+.c-summary-feedback-multi-response__category .c-summary-feedback-multi-response__category-head {
  background-color: var(--color-accent);
  color: #000;
}



.c-range-input-container .c-input-field__inner--stacked {
  background-color: var(--white);
  border: 2px solid var(--color-athens);
  border-radius: 10px;
  font-family: var(--font-body);
  font-size: 1rem;
  outline: none;
  padding: 0.6rem 0.85rem;
  transition: 250ms ease-in-out;
  width: 100%;
}



.c-range-input__input {
  margin: 0;
  margin-bottom: var(--g-spacing);
}

.c-range-input__value-popup {
	top: -50%;
	width: var(--g-spacing-large) !important;
}

.c-range-input__labels div {
  width: auto !important;
}


.c-date-picker tbody,
.c-date-picker tr,
.c-date-picker thead,
.c-date-picker td {
  vertical-align: middle !important;
}

.SingleDatePickerInput {
  background-color: var(--white);
  border: 2px solid var(--color-athens);
  border-radius: 10px;
  font-family: var(--font-body);
  font-size: 1rem;
  outline: none;
  transition: 250ms ease-in-out;
  width: 100%;
}

.SingleDatePickerInput_calendarIcon {
  vertical-align: baseline;
}

.CalendarMonth_table {
  margin-top: var(--g-spacing);
}


.SingleDatePicker_picker {
  margin-top: var(--g-spacing-x-small)
}

input[value="Next"],
input[value="Finish"] {
  display: none;
}