.c-bookmark-card {
  position: relative;
  width: calc(100% - 1rem);
}

.c-bookmark-card__layer--1 {
  width: 90%;
  height: 92%;
  right: -0.5rem;
  top: 50%;
  transform: translateY(-50%);
  background-color: #323a74;
}

.c-bookmark-card__layer--2 {
  width: 78%;
  height: 78%;
  right: -1rem;
  top: 50%;
  transform: translateY(-50%);
  background-color: #262d5d;
}
