.c-page-banner {
  background-color: var(--color-primary);
  background-position: center;
  background-size: cover;
  height: 10rem;
  max-width: var(--container-width);
  margin: 0 auto;
  position: relative;
  width: 100%;
}

.c-page-banner__blanket {
  height: 100%;
  left: 0;
  position: absolute;
  transition: 100ms ease-in-out;
  top: 0;
  width: 100%;
}

.c-page-banner__blanket:hover {
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 1;
}

.c-page-banner__image {
  align-items: center;
  background-color: var(--color-accent);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 3px solid var(--g-color-white);
  border-radius: 50%;
  color: var(--g-color-white);
  display: flex;
  height: var(--g-spacing-6x-large);
  font-size: var(--g-font-size-4x-large);
  font-weight: 600;
  justify-content: center;
  left: 50%;
  /* overflow: hidden; */
  position: absolute;
  top: 0;
  transform: translate(-50%, -50%);
  width: var(--g-spacing-6x-large);
}

.c-page-banner__settings {
  position: absolute;
  right: var(--g-spacing);
  top: var(--g-spacing);
}

.c-page-banner__settings span {
  margin-bottom: 0;
}

.c-page-banner__card {
  background-color: var(--g-color-white);
  border-radius: 10px;
  margin: 0 auto;
  max-width: var(--container-width-sm);
  margin-bottom: var(--g-spacing-large);
  margin-top: calc(-1 * var(--g-spacing-6x-large));
  padding: var(--g-spacing-2x-large);
  padding-top: var(--g-spacing-5x-large);
  position: relative;
  text-align: center;
  width: 90%;
}

.c-page-banner__title {
  font-family: var(--font-body);
  font-size: var(--g-font-size-x-large) !important;
  margin-bottom: var(--g-spacing-large);
}

.c-page-banner__description {
  color: #000;
  font-size: var(--g-font-size-small);
  line-height: 1.4;
  margin-bottom: var(--g-spacing-small);
}

.c-page-banner__description--minimal {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.c-page-banner__description-toggle {
  color: var(--color-primary);
  cursor: pointer;
  font-size: var(--g-font-size-x-small);
}

.c-page-banner__card .c-button--primary {
  border-radius: 10px;
  color: var(--g-color-white);
}

.c-page-banner__card .c-button__text {
  font-size: var(--g-font-size);
  justify-content: center;
  margin-bottom: 0;
}

.c-page-banner__inner {
  margin: 0 auto;
  width: 100%;
}

.c-page-banner__inner--spacer {
  margin-bottom: var(--g-spacing-x-large);
}

.c-page-banner__avatar-edit {
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  transition: 100ms ease-in-out;
}

.c-page-banner__avatar-edit svg {
  opacity: 0;
  stroke: var(--g-color-white);
  transition: 100ms ease-in-out;
  width: 50%;
}

.c-page-banner__avatar-edit:hover svg {
  opacity: 1;
}

.c-page-banner__cover-button {
  align-items: center;
  border: 2px solid var(--g-color-white);
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  color: var(--g-color-white);
  margin: var(--g-spacing);
  margin-left: auto;
  opacity: 0;
  padding: 0.65rem;
  white-space: nowrap;
}

.c-page-banner__cover-button svg {
  margin-right: 0.5rem;
  width: 1.5rem;
}

.c-page-banner:hover .c-page-banner__cover-button {
  opacity: 1;
}

@media (min-width: 64em) {
  .c-page-banner {
    height: 13rem;
  }

  .c-page-banner__card {
    margin-top: calc(-1 * var(--g-spacing-5x-large));
    padding-top: var(--g-spacing-6x-large);
  }

  .c-page-banner__image {
    height: 6rem;
    width: 6rem;
  }
}
