.c-online-status {
  border: 2px solid var(--g-color-white);
  border-radius: 50%;
  height: var(--g-spacing);
  position: absolute;
  background: #52c569;
  top: 0;
  right: -4px;
  width: var(--g-spacing);
}

.c-online-status--sm {
  height: var(--g-spacing-small);
  width: var(--g-spacing-small);
}

.c-online-status--lg {
  height: var(--g-spacing-large);
  width: var(--g-spacing-large);
}

.c-online-status::before {
  content: '';
  border: 5px solid #52c569;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  animation: pulsate infinite 1.5s;
}

@keyframes pulsate {
  0% {
    transform: translate(-50%, -50%) scale(1, 1);
    opacity: 0.5;
    width: 0%;
    height: 0%;
  }
  100% {
    transform: translate(-50%, -50%) scale(1.2, 1.2);
    opacity: 0;
    width: 100%;
    height: 100%;
  }
}
