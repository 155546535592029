.react-joyride__tooltip {
    padding: 2rem 1.5rem 1.5rem 1.5rem !important;
}
.react-joyride__tooltip button[aria-label="Next"], .react-joyride__tooltip button[aria-label="Last"] {
    background-color: var(--color-primary) !important;
    border-width: 3px !important;
    border-radius: 10px !important;
    padding: .85rem 1rem  !important;
    font-weight: 600;
}

.react-joyride__tooltip button[aria-label="Explore Stable Ground"] { 
    background-color: var(--white) !important;
    color: var(--color-primary) !important;
}


.react-joyride__tooltip button[aria-label="Back"] {
    color: var(--color-secondary) !important;
    font-weight: 600 !important;
}

.react-joyride__tooltip button[aria-label="Skip"] {
    color: var(--color-primary) !important;
    font-weight: 600 !important;
}
 