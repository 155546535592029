.c-connections-page__container {
  max-width: var(--container-width-sm);
  margin: 0 auto;
  padding: 0 0 var(--g-spacing-2x-large) 0;
  width: 90%;
}

.c-connections-page__section {
  margin-bottom: var(--g-spacing-2x-large);
}

.c-connections-page__container h2 {
  font-size: var(--g-font-size-large);
  margin-bottom: 1rem;
}
